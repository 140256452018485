document.addEventListener('DOMContentLoaded', function () {
    var loginType = document.getElementById('login-type');
    var urlParams = new URLSearchParams(window.location.search);
    var incorrect = urlParams.get('incorrect');
    if (incorrect) {
        loginType.value = 'password';
        document.getElementById('password').classList.remove('d-none');
        document.getElementById('sso').classList.add('d-none');
    }
    loginType.addEventListener('change', function () {
        switch (loginType.value) {
            case 'sso':
                document.getElementById('password').classList.add('d-none');
                break;
            case 'password':
                document.getElementById('sso').classList.add('d-none');
                break;
        }
        var typeDiv = document.getElementById(loginType.value);
        if (typeDiv.classList.contains('d-none')) {
            typeDiv.classList.remove('d-none');
        }
    });
});
